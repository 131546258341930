import React from "react"
import SEO from "../components/seo"
import Triangle from "../components/triangle/triangle"
// import Instagram from "../components/instagram/instagram"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"

const CareersPage = ({ data: { page, careers, instaImage1, instaImage2, instaImage3, instaImage4, instaImage5, instaImage6 }, location}) => {
  return (
    <>
      <SEO
        titleOverride={"Careers"}
        descriptionOverride={"We're proud of the way we work and the culture we have created. We're a close-knit team and value collaboration and creativity."}
        pathnameOverride={location.pathname}
      />

      <section className="mb-6 overflow-hidden lg:mb-24">
        <div className="container">
          <div className="flex flex-wrap items-center">
            <div className="w-full pr-6 mb-12 lg:w-5/12 content lg:mb-0 lg:pl-12 xl:pl-24 xl:pr-12">
              <div className="pt-4 pb-0 content content--reset lg:pt-8 xl:pt-12 lg:pb-5 xl:pb-8">
                <h1 className="h2">Join Our Team</h1>
                <p>We're proud of the way we work and the culture we have created. We're a close-knit team and value collaboration and creativity.</p>
                
                <p>Our team is growing and we're always on the lookout for new talent. Take a look at our current opportunities.</p>

                <a href="#opportunities" className="w-full btn btn--primary md:w-auto">Current Opportunities</a>
              </div>
            </div>

            <div className="w-full lg:w-1/2 lg:ml-auto">
              {/* <Instagram images={insta.edges} /> */}
              <div className="flex flex-wrap -mx-2 md:-mx-4 xl:-mx-6 insta">
                <div className="w-1/2 px-2 mb-3 md:w-1/3 md:mb-5 xl:mb-12 md:px-4 xl:px-6 insta__object">
                  <Img fluid={ instaImage1.childImageSharp.fluid } className="object-cover w-full max-w-full insta__img"/>
                </div>
                <div className="w-1/2 px-2 mb-3 md:w-1/3 md:mb-5 xl:mb-8 md:px-4 xl:px-6 insta__object">
                  <Img fluid={ instaImage2.childImageSharp.fluid } className="object-cover w-full max-w-full insta__img"/>
                </div>
                <div className="w-1/2 px-2 mb-3 md:w-1/3 md:mb-5 xl:mb-8 md:px-4 xl:px-6 insta__object">
                  <Img fluid={ instaImage3.childImageSharp.fluid } className="object-cover w-full max-w-full insta__img"/>
                </div>
                <div className="w-1/2 px-2 mb-3 md:w-1/3 md:mb-5 xl:mb-8 md:px-4 xl:px-6 insta__object">
                  <Img fluid={ instaImage4.childImageSharp.fluid } className="object-cover w-full max-w-full insta__img"/>
                </div>
                <div className="w-1/2 px-2 mb-3 md:w-1/3 md:mb-5 xl:mb-8 md:px-4 xl:px-6 insta__object">
                  <Img fluid={ instaImage5.childImageSharp.fluid } className="object-cover w-full max-w-full insta__img"/>
                </div>
                <div className="w-1/2 px-2 mb-3 md:w-1/3 md:mb-5 xl:mb-8 md:px-4 xl:px-6 insta__object">
                  <Img fluid={ instaImage6.childImageSharp.fluid } className="object-cover w-full max-w-full insta__img"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="mb-12 lg:mb-12" id="opportunities">
        <div className="container">
          <div className="flex flex-wrap items-center">
            <div className="w-full mb-10 lg:w-1/2 lg:mb-0">
              <div className="p-10 bg-secondary lg:p-12 xl:p-24">
                <h3 className="mb-5 text-white lg:mb-8">What Makes Us Great?</h3>
                <ul>
                  <li className="relative py-2 pl-5 text-base md:text-xl text-secondary-light font-display"><span className="absolute top-0 left-0 mt-2 text-primary"><Triangle size="small" /></span><span className="inline">A competitive salary, pension plan and healthcare benefit scheme</span></li>
                  <li className="relative py-2 pl-5 text-base md:text-xl text-secondary-light font-display"><span className="absolute top-0 left-0 mt-2 text-primary"><Triangle size="small" /></span><span className="inline">Hybrid working policy</span></li>
                  <li className="relative py-2 pl-5 text-base md:text-xl text-secondary-light font-display"><span className="absolute top-0 left-0 mt-2 text-primary"><Triangle size="small" /></span><span className="inline">Flexible working hours</span></li>
                  <li className="relative py-2 pl-5 text-base md:text-xl text-secondary-light font-display"><span className="absolute top-0 left-0 mt-2 text-primary"><Triangle size="small" /></span><span className="inline">Minimum 25 days annual leave</span></li>
                  <li className="relative py-2 pl-5 text-base md:text-xl text-secondary-light font-display"><span className="absolute top-0 left-0 mt-2 text-primary"><Triangle size="small" /></span><span className="inline">Learning & development programmes</span></li>
                  <li className="relative py-2 pl-5 text-base md:text-xl text-secondary-light font-display"><span className="absolute top-0 left-0 mt-2 text-primary"><Triangle size="small" /></span><span className="inline">Seasonal company social events</span></li>
                  <li className="relative py-2 pl-5 text-base md:text-xl text-secondary-light font-display"><span className="absolute top-0 left-0 mt-2 text-primary"><Triangle size="small" /></span><span className="inline">Team meals, activities and workiversary treats!</span></li>
                  <li className="relative py-2 pl-5 text-base md:text-xl text-secondary-light font-display"><span className="absolute top-0 left-0 mt-2 text-primary"><Triangle size="small" /></span><span className="inline">Clubs (inc monthly book club) & weekly quiz</span></li>
                  <li className="relative py-2 pl-5 text-base md:text-xl text-secondary-light font-display"><span className="absolute top-0 left-0 mt-2 text-primary"><Triangle size="small" /></span><span className="inline">Nottingham city centre location <span class="block">(see perks below)</span></span></li>
                </ul>
              </div>
            </div>

            <div className="w-full lg:w-1/2">
              <div className="px-12 lg:p-12 xl:p-24">
                <h3 className="mb-4 text-primary lg:text-center lg:mb-6">Current Opportunities</h3>
                <ul>
                {careers && careers.edges.length > 0 ? (
                  careers.edges.map(({ node }, index) => (
                    <li key={index}>
                      <Link className="relative block py-4 pl-5 text-lg transition duration-500 ease-in-out border-b-2 lg:text-xl border-grey-light hover:text-primary focus:text-primary" to={`/careers/${node.slug}`}>
                        <span className="absolute top-0 left-0 mt-4 text-primary"><Triangle size="small" /></span><span className="inline">{ node.title }</span>
                      </Link>
                    </li>
                  ))
                ) : (
                  <p className="lg:text-center">There are currently no positions available</p>
                )}

                  
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="mb-12 lg:mb-24" id="opportunities">
        <div className="container">
          <div className="flex flex-wrap items-center">

            <div className="w-full mb-10 lg:w-1/2 lg:mb-0 bg-primary lg:bg-white">
              <div className="p-10 lg:p-12 xl:p-24">
                <h3 className="mb-5 text-white lg:text-primary lg:mb-8">City Centre Office</h3>
                <ul>
                  <li className="relative py-2 pl-5 text-base md:text-xl text-white lg:text-secondary-dark"><span className="absolute top-0 left-0 mt-2 text-white lg:text-secondary-dark"><Triangle size="small" /></span><span className="inline">Adtrak office & communal spaces</span></li>
                  <li className="relative py-2 pl-5 text-base md:text-xl text-white lg:text-secondary-dark"><span className="absolute top-0 left-0 mt-2 text-white lg:text-secondary-dark"><Triangle size="small" /></span><span className="inline">Onsite Barista</span></li>
                  <li className="relative py-2 pl-5 text-base md:text-xl text-white lg:text-secondary-dark"><span className="absolute top-0 left-0 mt-2 text-white lg:text-secondary-dark"><Triangle size="small" /></span><span className="inline">Free afternoon beer and prosecco</span></li>
                  <li className="relative py-2 pl-5 text-base md:text-xl text-white lg:text-secondary-dark"><span className="absolute top-0 left-0 mt-2 text-white lg:text-secondary-dark"><Triangle size="small" /></span><span className="inline">Weekly Thursday breakfast & networking events</span></li>
                  <li className="relative py-2 pl-5 text-base md:text-xl text-white lg:text-secondary-dark"><span className="absolute top-0 left-0 mt-2 text-white lg:text-secondary-dark"><Triangle size="small" /></span><span className="inline">Dog friendly</span></li>
                  <li className="relative py-2 pl-5 text-base md:text-xl text-white lg:text-secondary-dark"><span className="absolute top-0 left-0 mt-2 text-white lg:text-secondary-dark"><Triangle size="small" /></span><span className="inline">Free on-site gym and wellness centre</span></li>
                  <li className="relative py-2 pl-5 text-base md:text-xl text-white lg:text-secondary-dark"><span className="absolute top-0 left-0 mt-2 text-white lg:text-secondary-dark"><Triangle size="small" /></span><span className="inline">Access to use other UK office sites</span></li>
                </ul>
              </div>
            </div>

            <div className="w-full lg:w-1/2">
                <Img fluid={page.featuredMedia.fluid} className="w-full h-full" />
            </div>

          </div>
        </div>
      </section>
    </>
  )
}

export default CareersPage


// export const query = graphql`
//   query CareersPageQuery {
//     careers: allDatoCmsCareer {
//       edges {
//         node {
//           title
//           slug
//         }
//       }
//     }
//     insta: allInstaNode(sort: {order: DESC, fields: timestamp}, limit: 6) {
//       edges {
//         node {
//           localFile {
//             childImageSharp {
//               fixed(width: 500, height: 500) {
//                 ...GatsbyImageSharpFixed
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// `

export const query = graphql`
  query CareersPageQuery {
    instaImage1: file(relativePath: { eq: "insta1.jpg" }) { 
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    instaImage2: file(relativePath: { eq: "insta2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    instaImage3: file(relativePath: { eq: "insta3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    instaImage4: file(relativePath: { eq: "insta4.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    instaImage5: file(relativePath: { eq: "insta5.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    instaImage6: file(relativePath: { eq: "insta6.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    careers: allDatoCmsCareer {
      edges {
        node {
          title
          slug
        }
      }
    }
    page: datoCmsPage {
      featuredMedia {
        fluid(
          maxWidth: 900
          imgixParams: {h: "500", w: "800", fit: "crop", crop: "center"}) {
          ...GatsbyDatoCmsFluid_noBase64
        }
      }
    }
  }
`